<template>
  <div class="background">
    <Categories />
  </div>
</template>

<script>
import Categories from "@/components/elements/dashboard/categories/Categories";

export default {
  name: "DocumentManagementCategories",

  components: {
    Categories,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
